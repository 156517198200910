import React from "react";
import ReactDOM from "react-dom";
import Routes from "routes";
import {Provider} from "react-redux";
import {store} from "./helpers";
import {NotificationContainer} from "react-notifications";

import "./assets/css/index.scss";
import "font-awesome/css/font-awesome.min.css";

ReactDOM.render(
  <Provider store={store}>
    <Routes />
    <NotificationContainer />
  </Provider>,
  document.getElementById("root")
);
