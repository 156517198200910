import React, {Fragment, useEffect, useState} from "react";
import {getToken, history} from "helpers";
import jwt_decode from "jwt-decode";
import {useLocation} from "react-router-dom";

import Header from "../component/Header/index";

export const MainLayout = ({ children }) => {
  const location = useLocation();

  let [sideBar, setSideBar] = useState([
    {
      to: "/admin/dashboard",
      icon: "icon-dashboard align-icon",
      name: "Dashboard",
    },
    {
      to: "/admin/sku-reports",
      icon: "icon-dashboard align-icon",
      name: "SKU Reports",
    },
    {
      to: "/admin/staffs&roles/list?tab=role&page=1",
      icon: "icon-staffs-roles align-icon",
      name: "Staffs & Roles",
    },
    {
      to: "/admin/testimonials",
      icon: "icon-testimonials align-icon",
      name: "Testimonials",
    },
    {
      to: "/admin/userdata/list?page=1",
      icon: "icon-user-data align-icon",
      name: "User Data",
    },
    {
      to: "/admin/offermanagement/offers?page=1",
      icon: "icon-coupon align-icon",
      name: "Offer Management",
    },
    {
      to: "/admin/couponmanagement/coupon?page=1",
      icon: "icon-coupon align-icon",
      name: "Coupon Management",
    },
    {
      to: "/admin/ordermanagement/list?tab=newOrder&paymentType=&page=1",
      icon: "icon-order align-icon",
      name: "Order Management",
    },
    {
      to: "/admin/hub?page=1&size=10",
      icon: "icon-order align-icon",
      name: "Hub",
    },
    {
      to: "/admin/bannermanagement/banner",
      icon: "icon-banner align-icon",
      name: "Banner Management",
    },
    {
      to: "/admin/productmanagement/product?page=1",
      icon: "icon-product align-icon",
      name: "Product Management",
    },
    {
      to: "/admin/contentmanagement/content?page=1",
      icon: "icon-product align-icon",
      name: "Content Management",
    },
    {
      to: "/admin/moleculeManagement/product?page=1",
      icon: "icon-product align-icon",
      name: "Molecule Management",
    },
    {
      to: "/admin/leadmanagement/list?tab=allleads&page=1",
      icon: "icon-blog align-icon",
      name: "Lead Management",
    },
    {
      to: "/admin/paprequest/list?page=1",
      icon: "icon-pap-request align-icon",
      name: "PAP Request",
    },
    {
      to: "/admin/importmedicine/list?tab=newRequest&page=1",
      icon: "icon-import align-icon",
      name: "Import Medicine",
    },
    {
      to: "/admin/carerrequest/list?page=1",
      icon: "icon-pap-request align-icon",
      name: "Carer Request",
    },
    {
      to: "/admin/conditionmanagement",
      icon: "icon-speciality align-icon",
      name: "Condition Management",
    },
    {
      to: "/admin/superspecialitymanagement",
      icon: "icon-speciality align-icon",
      name: "Super Speciality Management",
    },
    {
      to: "/admin/homepagemanagement",
      icon: "icon-speciality align-icon",
      name: "Homepage Management",
    },
    {
      to: "/admin/reviews",
      icon: "icon-testimonials align-icon",
      name: "Review Management",
    },
    {
      to: "/admin/suppliermanagement/list?page=1",
      icon: "icon-supplier align-icon",
      name: "Supplier Management",
    },
    {
      to: "/admin/blog",
      icon: "icon-blog align-icon",
      name: "Blog",
    },
    {
      to: "/admin/news",
      icon: "icon-blog align-icon",
      name: "News",
    },
    {
      to: "/admin/feedback/list?page=1&size=10",
      icon: "icon-blog align-icon",
      name: "Feedback",
    },
    {
      to: "/admin/faqmanagement",
      icon: "icon-faq align-icon",
      name: "FAQ Management",
    },
    {
      to: "/admin/terms&condition",
      icon: "icon-terms align-icon",
      name: "Terms & Condition",
    },
    {
      to: "/admin/globalVariables",
      icon: "icon-gobal align-icon",
      name: "Global Variables",
    },
    {
      to: "/admin/subscribers/list?page=1",
      icon: "icon-subscriber align-icon",
      name: "Subscribers",
    },
    {
      to: "/admin/stockNotification/list?page=1",
      icon: "icon-blog align-icon",
      name: "Stock Notification",
    },
  ]);
  useEffect(() => {
    if (getToken() != null) {
      let token = getToken();
      let userInfo = jwt_decode(token);
      if (userInfo.userType != 1 && userInfo.permission) {
        let filter = sideBar.filter((value) => {
          if (userInfo.permission[value.name] && userInfo.permission[value.name].allow) {
            return value;
          }
        }).sort()
        // rolebased URL blocking
        let access,
          paths = history.location.pathname.split("/").slice(2).map(data => data.toLowerCase().replace(/\s/g, "")),
          query = {},
          quer = history.location.search.slice(1).split("&");
        quer.length && quer.map(queryData => {
          let data = queryData.split("=")
          if (data[0]) query = {
            ...query,
            [data[0]]: data[1].toLowerCase().replace(/\s/g, "")
          }
        })
        access = Object.keys(userInfo.permission).filter((value1) => {
          let userData = userInfo.permission[value1],
            value1Con = value1.toLowerCase().replace(/\s/g, "");
          //first dimension of permission
          if (paths.length == 1 && paths[0] === value1Con && userData.allow) {
            return true
          }
          else if (paths.length == 2) {
            //first dimension with params key
            if (userData.allowedParams && userData.allowedParams.includes(paths[1]) && paths[0] === value1Con && userData.allow ) {
              return true
            } 
            //Url length 2 but permission doesn't have childrens
            else if (paths[0] === value1Con && !userData.childrens && userData.allow) return true
            // Second dimension of permission
            else if (paths[0] === value1Con && userData.allow && userData.childrens) {
              for (let value2 in userData.childrens) {
                let value2Con = value2.toLowerCase().replace(/\s/g, "")
                // 2 parama and childrens permission or childrens doesn't availble
                if (userData.childrens[value2].url && userData.childrens[value2].url.includes(paths[1]) && userData.childrens[value2].allow) {
                  return true
                }
                // Third dimension of permission
                else if (userData.childrens[value2].query && query[userData.childrens[value2].key] == userData.childrens[value2].query && userData.childrens[value2].allow) {
                  // path doesn't match with 2nd permission and have childrens
                  if(value2Con != paths[1] && userData.childrens[value2].childrens){
                    for(let value3 in userData.childrens[value2].childrens){
                      let value3Con = value3.toLowerCase().replace(/\s/g, "")
                      // 3rd dimension URL matching
                      if(userData.childrens[value2].childrens[value3].url &&  userData.childrens[value2].childrens[value3].url.includes(paths[1])  && userData.childrens[value2].childrens[value3].allow){
                        return true
                      } 
                    }
                  }
                  // Second dimension query miss
                  else{
                    return true
                  }
                }
              }
            }

          }
        });
        if (
          access.length === 0 &&
          paths[0] !== "account" &&
          paths[0] !== "notification"
        ) {
          history.push(filter[0].to);
        }
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  }, [location.pathname]);

  return (
    <Fragment>
      <Header></Header>
      <div className="main-background">
        <div className="main-content">{children}</div>
      </div>
    </Fragment>
  );
};
