import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,} from "reactstrap";
import {getOrderPermission, getToken, history} from "../../helpers/index";
import {Toast} from "../../service/toast";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {validateToken} from "service/Common";
import {getAllNotification} from "service/Notification";
import {signout} from "service/Auth";
import * as sideMenu from "../../action/home";
import * as sessionExpire from "../../action/home";
import * as userInfo from "../../action/home";
import * as getUserId from "../../action/home";
import Avatar from "react-avatar";
import jwt_decode from "jwt-decode";
import SessionExpire from "../SessionExpire/index";

import "assets/fonts/admin-icons/style.css";

import "../Header/style.scss";

const tab = {
  "New Request": "newOrder",
  "Under Process": "underProcess",
  "Completed": "completed",
  "Cancelled": "cancelled",
  "Leads": "leads",
  "Returns": "returns"
}
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBar: [
        {
          to: "/admin/dashboard",
          icon: "icon-dashboard align-icon",
          name: "Dashboard",
        },
        {
          to: "/admin/skureports",
          icon: "icon-supplier align-icon",
          name: "SKU Reports",
        },
        {
          to: "/admin/staffs&roles/list?tab=role&page=1",
          icon: "icon-staffs-roles align-icon",
          name: "Staffs & Roles",
        },
        {
          to: "/admin/testimonials",
          icon: "icon-testimonials align-icon",
          name: "Testimonials",
        },
        {
          to: "/admin/userdata/list?page=1",
          icon: "icon-user-data align-icon",
          name: "User Data",
        },
          {
            to: "/admin/offermanagement/offers?page=1",
            icon: "icon-coupon align-icon",
            name: "Offer Management",
          },
        {
          to: "/admin/couponmanagement/coupon?page=1",
          icon: "icon-coupon align-icon",
          name: "Coupon Management",
        },
        {
          to: `/admin/ordermanagement/list?tab=${tab[getOrderPermission()]}&paymentType=&page=1&size=10`,
          icon: "icon-order align-icon",
          name: "Order Management",
        },
        {
          to: "/admin/hub?page=1&size=10",
          icon: "icon-order align-icon",
          name: "Hub",
        },
        {
          to: `/admin/pincodemanagement/list?page=1&size=10`,
          icon: "icon-banner align-icon",
          name: "Pincode Management",
        },
        {
          to: "/admin/bannermanagement/banner",
          icon: "icon-banner align-icon",
          name: "Banner Management",
        },
        {
          to: "/admin/productmanagement/product?page=1&size=10",
          icon: "icon-product align-icon",
          name: "Product Management",
        },
        {
          to: "/admin/contentmanagement/content?page=1&size=10",
          icon: "icon-product align-icon",
          name: "Content Management",
        },
        {
          to: "/admin/moleculeManagement/product?page=1&size=10",
          icon: "icon-pap-request align-icon",
          name: "Molecule Management",
        },
        {
          to: "/admin/leadmanagement/list?tab=leads&page=1",
          icon: "icon-blog align-icon",
          name: "Lead Management",
        },
        {
          to: "/admin/paprequest/list?page=1",
          icon: "icon-pap-request align-icon",
          name: "PAP Request",
        },
        {
          to: "/admin/importmedicine/list?tab=newRequest&page=1",
          icon: "icon-import align-icon",
          name: "Import Medicine",
        },
        {
          to: "/admin/carerrequest/list?page=1",
          icon: "icon-pap-request align-icon",
          name: "Carer Request",
        },
        {
          to: "/admin/conditionmanagement",
          icon: "icon-speciality align-icon",
          name: "Condition Management",
        },
        {
          to: "/admin/superspecialitymanagement",
          icon: "icon-speciality align-icon",
          name: "Super Speciality Management",
        },
        {
          to: "/admin/homepagemanagement",
          icon: "icon-speciality align-icon",
          name: "Homepage Management",
        },
        {
          to: "/admin/reviewmanagement",
          icon: "icon-testimonials align-icon",
          name: "Review Management",
        },
        {
          to: "/admin/suppliermanagement/list?page=1",
          icon: "icon-supplier align-icon",
          name: "Supplier Management",
        },
        {
          to: "/admin/blog",
          icon: "icon-blog align-icon",
          name: "Blog",
        },
        {
          to: "/admin/news",
          icon: "icon-blog align-icon",
          name: "News",
        },
        {
          to: "/admin/feedback/list?page=1&size=10",
          icon: "icon-blog align-icon",
          name: "Feedback",
        },
        {
          to: "/admin/faqmanagement",
          icon: "icon-faq align-icon",
          name: "FAQ Management",
        },
        {
          to: "/admin/terms&condition",
          icon: "icon-terms align-icon",
          name: "Terms & Condition",
        },
        {
          to: "/admin/globalVariables",
          icon: "icon-gobal align-icon",
          name: "Global Variables",
        },
        {
          to: "/admin/subscribers/list?page=1",
          icon: "icon-subscriber align-icon",
          name: "Subscribers",
        },
        {
          to: "/admin/stockNotification/list?page=1&size=10",
          icon: "icon-blog align-icon",
          name: "Stock Notification",
        },
      ],
      drawer: false,
      badge: 0,
      userDetail: {},
      permission: [],
    };
  }

  async componentDidMount() {
    if (getToken() != null) {
      let token = getToken();
      let userInfo = jwt_decode(token);
      if (![1, 2].includes(userInfo.userType)){
        await signout().catch(e => console.log(e))
        localStorage.clear()
        sessionStorage.clear()
        return {}
      }
      this.validateUser();
      this.props.actions.userInfo(userInfo);
      this.updateNotification();

      if (userInfo.userType != 1 && userInfo.permission) {
        let filter = this.state.sideBar.filter((value) => {
          if (userInfo.permission[value.name] && userInfo.permission[value.name].allow) {
            return value;
          }
        });
        this.setState({
          sideBar: filter,
        });
      }
    } else {
      history.push("/");
      localStorage.clear();
    }
  }

  validateUser = async () => {
    try {
      let response = await validateToken();
      if (response.status == 200) {
        this.props.actions.getUserId(response.data.user.id)
        localStorage.setItem('userId', response.data.user.id);
        return null;
      }
      else if(response.status===401) {
        this.props.actions.sessionExpire(true);
      }
    } catch (error) {
      if (error.response && error.response.status == 401) {
        this.props.actions.sessionExpire(true);
      }else {
        return null;
      }
    }
  };
  updateNotification = async () => {
    try {
      let response = await getAllNotification({ page: 1 });
      if (response.status == 200) {
        this.setState({
          badge: response.data.data.pageMeta.total,
        });
      }
    } catch (error) {
      if(error.response) {
        Toast({ type: "error", message: error.response.data.message });
      }
      else {
        Toast({ type: "error", message: error.message });
      }
    }
  };
  handleDrawer() {
    this.props.actions.sideMenu(!this.props.drawer);
  }
  async onLogout() {
    await signout().catch(e => console.log(e))
    localStorage.clear();
    sessionStorage.clear();
    history.push("/");
  }
  checkActiveRoute(path) {
    let subPath = path.split("/")[2];
    let pattern = new RegExp(subPath, "gi");
    let isActive = history.location.pathname.match(pattern);
    return isActive ? true : false;
  }
  render() {
    return (
      <>
        <div
          className={
            this.props.drawer
              ? "show-drawer custom-scroll"
              : "hide-drawer custom-scroll"
          }
        >
          <ul>
            {this.state.sideBar.map((value, index) => {
              return (
                <div key={index}>
                  <li>
                    <NavLink
                      onClick={() => this.handleDrawer()}
                      to={value.to}
                      id="navlink"
                      className={
                        this.checkActiveRoute(value.to)
                          ? "active-icon"
                          : "inactive-icon"
                      }
                    >
                      <i className={value.icon}></i>
                      <span>{value.name}</span>
                    </NavLink>
                  </li>
                </div>
              );
            })}
          </ul>
        </div>
        <div
          className={this.props.drawer ? "overlay" : "overlay-none"}
          onClick={() => this.handleDrawer()}
        ></div>
        <div className="header">
          <div>
            <img
              className="menu"
              draggable={false}
              onClick={() => this.handleDrawer()}
              src={require("../../assets/images/menu.png")}
            />
            <img
              className="logo"
              draggable={false}
              src={require("../../assets/images/logo.svg")}
            />
          </div>
          <div>
            <NavLink id="navlink" to="/admin/notification">
              <img
                className="notify"
                draggable={false}
                src={require("../../assets/images/notification.svg")}
              />
              {this.state.badge >= 1 && (
                <div className="notify-badge">
                  <span>{this.state.badge}</span>
                </div>
              )}
            </NavLink>
            <div className="user-profile">
              <div className="avatar">
                <Avatar
                  color={Avatar.getRandomColor(["green"])}
                  name={this.props.userDetail.name}
                  maxInitials={1}
                  size="45"
                  textSizeRatio={2}
                  round={true}
                />
              </div>
              <UncontrolledButtonDropdown direction="down">
                <DropdownToggle tag="span" data-toggle="dropdown">
                  <span id="user-name">{this.props.userDetail.name}</span>
                </DropdownToggle>
                <DropdownMenu className="header-dropdown">
                  <NavLink id="navlink" to="/admin/account/view">
                    <DropdownItem>
                      <span id="header-icon" className="far fa-user"></span>
                      <span>My Profile</span>
                    </DropdownItem>
                  </NavLink>
                  <DropdownItem onClick={() => this.onLogout()}>
                    <div>
                      <span
                        id="header-icon"
                        className="far fa-sign-out-alt"
                      ></span>
                      <span>Logout</span>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
          <SessionExpire />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    drawer: state.home.drawer,
    userDetail: state.home.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...sideMenu, ...sessionExpire, ...userInfo,...getUserId },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
