import {applyMiddleware, createStore} from "redux";
import {reducers} from "../reducer";
import thunk from "redux-thunk";
import {Toast} from "service/toast";
import moment from "moment";
import jwt_decode from "jwt-decode";
import ls from "localstorage-slim";
import {permissions} from "../config/permission.json"

export const store = createStore(
  reducers,
  applyMiddleware(thunk.withExtraArgument({ Toast }))
);
export const history = require("history").createBrowserHistory({});

export const getMonth = (month) => {
  switch (month) {
    case "Jan":
      return 0;
    case "Feb":
      return 1;
    case "Mar":
      return 2;
    case "Apr":
      return 3;
    case "May":
      return 4;
    case "Jun":
      return 5;
    case "Jul":
      return 6;
    case "Aug":
      return 7;
    case "Sep":
      return 8;
    case "Oct":
      return 9;
    case "Nov":
      return 10;
    case "Dec":
      return 11;
    default:
      return 1;
  }
};

export const dateDiff = (orderStartDate, orderEndDate = false) => {
  let finalValue;

  let currentDate = moment([
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  ]);

  let initialDate = String(orderStartDate).split(" ")[0];

  let finalDate = String(orderEndDate).split(" ")[0];

  let startDate = moment([
    String(orderStartDate).split(",")[0].split(" ")[2],
    getMonth(String(orderStartDate).split(",")[0].split(" ")[1]),
    initialDate.substring(0, initialDate.length - 2),
  ]);

  let endDate = moment([
    String(orderEndDate).split(",")[0].split(" ")[2],
    getMonth(String(orderEndDate).split(",")[0].split(" ")[1]),
    finalDate.substring(0, finalDate.length - 2),
  ]);

  if (orderEndDate) {
    finalValue = endDate.diff(startDate, "days");
  } else {
    finalValue = currentDate.diff(startDate, "days");
  }

  return finalValue > 1 ? `${finalValue} days` : `${finalValue} day`;
};

export const isGeneralImage = (data) => {
  let urls = [];
  data.items.map((list) => {
    list.imgUrls && list.imgUrls.map((url) => {
      urls.push(url);
    });
  });
  let isGeneral = urls.some((list) => {
    return String(list.url ? list.url : list).includes("General+New");
  });
  return isGeneral ? "Yes" : "No";
};
export const updateRoleData = (roleData,hubData) => {
  let checkBoxValue = JSON.parse(JSON.stringify(permissions))
  checkBoxValue["Hub"].childrens = hubData;

  Object.keys(checkBoxValue).map(value1 => {
    if (roleData[value1]) {
      checkBoxValue[value1].allow = roleData[value1].allow
      checkBoxValue[value1].childrens && Object.keys(checkBoxValue[value1].childrens).map(value2 => {
        if (roleData[value1].childrens && roleData[value1].childrens[value2]) {
          checkBoxValue[value1].childrens[value2].allow = roleData[value1].childrens[value2].allow
          checkBoxValue[value1].childrens[value2].childrens && Object.keys(checkBoxValue[value1].childrens[value2].childrens).map(value3 => {
            if (roleData[value1].childrens[value2].childrens[value3]) {
              checkBoxValue[value1].childrens[value2].childrens[value3].allow = roleData[value1].childrens[value2].childrens[value3].allow
            }
          })
        }
      })
    }
  })
  return checkBoxValue
}
export const setToken = token => {
  let userData = jwt_decode(token)
  if(userData.permission) ls.set('PSDnuDSD3I_pxsa23', updateRoleData(userData.permission), {encrypt: true})
  ls.set('PXgNtTli3A_px_fp', token, {encrypt: true})
}
export const getToken = (() => ls.get('PXgNtTli3A_px_fp', {decrypt: true}))

export const getPermData = (() => ls.get('PSDnuDSD3I_pxsa23', {decrypt: true}))

export const getAdminData = () => jwt_decode(getToken())

export const getPermission = (value1, value2, value3) => {
  let {userType} = jwt_decode(getToken()),
      permission = getPermData();
  if(userType != 1){
    if(value3 && value2 && value1) return permission[value1].childrens[value2].childrens[value3].allow
    else if(value2 && value1) return permission[value1].childrens[value2].allow
    else if(value1) return permission[value1].allow
  }else return true
}
export const getOrderPermission = (access) => {
  let userData = jwt_decode(getToken()),
      permission = getPermData(),
      orderManagement = permission ? permission["Order Management"].childrens : {};
  if(access){
    return userData.userType == 1 ? "New Request,Under Process,Completed,Cancelled,Returns,Leads" : (Object.keys(orderManagement).filter(perm => orderManagement[perm].childrens[access].allow)).join()
  }else{
    return userData.userType == 1 ? "New Request" : Object.keys(orderManagement).find(key => orderManagement[key].allow)
  }
}
export function findGetParameter(parameterName) {
  var result = null,
    tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}
export const getYearDropDown = () => {
  let min = 2021;
  let max = new Date().getFullYear();
  let options = Array(max - min + 1)
    .fill("")
    .map((_, index) => {
      return min + index;
    })
    .sort((a, b) => {
      return b - a;
    });
  return options;
};

export const converToINR = (num) => {
  return Number(num).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
};

export const getPaymentStatus = (status) => {
  if (status == 0) {
    return "Pending";
  } else if (status == 1) {
    return "Failed";
  } else if (status == 2) {
    return "Success";
  } else if (status == 3) {
    return "Refund";
  } else if (status == 4) {
    return "Refund Initiated";
  } else if (status == 5) {
    return "Refund Completed";
  } else if (status == 6) {
    return "Cancelled";
  } else {
    return null;
  }
};

export const addressRegex = /^[0-9A-Za-z,-/. ]+$/;


/*export function convertTimeToAMPM1(timeString) {
  console.log("timeString", timeString);
  const [, hours, minutes, seconds] = timeString.match(/T(\d{2}):(\d{2}):(\d{2})/);
  const parsedHours = parseInt(hours, 10);
  const parsedMinutes = parseInt(minutes, 10);
  const parsedSeconds = parseInt(seconds, 10);
  const timezoneOffsetHours = Math.floor(timeString.match(/[+-]\d{2}/)[0] / 100);
  const adjustedHours = (parsedHours + timezoneOffsetHours) % 24;
  const ampm = adjustedHours >= 12 ? 'PM' : 'AM';
  const hours12 = adjustedHours % 12 || 12;
  const formattedTime = `${hours12}:${minutes}:${seconds} ${ampm}`;
  return formattedTime;
}*/

export function convertTimeToAMPM(timeString) {
  console.log("timeString", timeString);
  // Parse the time string using moment
  let time = moment(timeString);

  // Format the time to 12-hour format with AM/PM
  const formattedTime = time.format('hh:mm:ss A');

  return formattedTime;
}


