import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, ModalBody} from "reactstrap";
import {history} from "../../helpers";
import {bindActionCreators} from "redux";
import * as sessionExpire from "../../action/home";
import {signout} from "service/Auth";

import "../SessionExpire/style.scss";

class SessionExpire extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async onLogout() {
    await signout().catch(e => console.log(e))
    localStorage.clear();
    sessionStorage.clear();
    history.push("/");
    this.props.actions.sessionExpire(false);
  }
  render() {
    return (
      <>
        <Modal isOpen={this.props.isSessionExpire}>
          <ModalBody>
            <div className="token-expire">
              <b>Session Expired</b>
              <span>Please login in again</span>
              <hr></hr>
              <button onClick={() => this.onLogout()}>Login</button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isSessionExpire: state.home.sessionExpire,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionExpire, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionExpire);
