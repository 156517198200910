const routers = [
  {
    path: "/",
    redirect: "/auth",
  },
  {
    component: "AuthLayout",
    path: "/auth",
    auth: false,
    name: "Auth",
    exact: false,
    redirect: "/auth/login",
    childrens: [
      {
        component: "Login",
        path: "/login",
        componentPath: "pages/Auth/Login",
        name: "Login",
        auth: false,
        exact: true,
      },
      {
        component: "ForgotPassword",
        path: "/forgotPassword",
        componentPath: "pages/Auth/ForgotPassword",
        name: "ForgotPassword",
        auth: false,
        exact: true,
      },
      {
        component: "Verification",
        path: "/verification",
        componentPath: "pages/Auth/Verification",
        name: "Verification",
        auth: false,
        exact: true,
      },
      {
        component: "ResetPassword",
        path: "/resetPassword",
        componentPath: "pages/Auth/ResetPassword",
        name: "ResetPassword",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/admin",
    auth: false,
    name: "Dashboard",
    exact: false,
    redirect: "/admin/dashboard",
    childrens: [
      // DASHBOARD
      {
        component: "DashboardMenu",
        path: "/dashboard",
        componentPath: "pages/Dashboard/DashboardMenu/Dashboard",
        name: "DashboardMenu",
        auth: false,
        exact: true,
      },
      // SKU Reports
      {
        component: "SKUReports",
        path: "/skureports",
        componentPath: "pages/Dashboard/SKUReports",
        name: "SKUReports",
        auth: false,
        exact: true,
      },
      // STAFFS AND ROLES
      {
        component: "StaffsAndRoles",
        path: "/staffs&roles/list",
        componentPath: "pages/Dashboard/StaffsAndRoles/StaffsAndRoles",
        name: "Roles",
        auth: false,
        exact: true,
      },
      {
        component: "AddRole",
        path: "/staffs&roles/addRole",
        componentPath: "pages/Dashboard/StaffsAndRoles/AddRole",
        name: "AddRole",
        auth: false,
        exact: true,
      },
      {
        component: "AddStaff",
        path: "/staffs&roles/addStaff",
        componentPath: "pages/Dashboard/StaffsAndRoles/AddStaff",
        name: "AddStaff",
        auth: false,
        exact: true,
      },
      {
        component: "EditStaff",
        path: "/staffs&roles/editStaff",
        componentPath: "pages/Dashboard/StaffsAndRoles/EditStaff",
        name: "EditStaff",
        auth: false,
        exact: true,
      },
      {
        component: "EditRole",
        path: "/staffs&roles/editRole",
        componentPath: "pages/Dashboard/StaffsAndRoles/EditRole",
        name: "EditRole",
        auth: false,
        exact: true,
      },
      // TESTOMONIAL
      {
        component: "TestimonialDetails",
        path: "/testimonials",
        componentPath: "pages/Dashboard/Testimonial/TestimonialDetails",
        name: "TestimonialDetails",
        auth: false,
        exact: true,
      },
      {
        component: "AddTestimonial",
        path: "/testimonials/add",
        componentPath: "pages/Dashboard/Testimonial/AddTestimonial",
        name: "AddTestimonial",
        auth: false,
        exact: true,
      },
      {
        component: "EditTestimonial",
        path: "/testimonials/edit",
        componentPath: "pages/Dashboard/Testimonial/EditTestimonial",
        name: "EditTestimonial",
        auth: false,
        exact: true,
      },
      // USER DATA
      {
        component: "UserList",
        path: "/userdata/list",
        componentPath: "pages/Dashboard/UserData/UserList",
        name: "UserList",
        auth: false,
        exact: true,
      },
      {
        component: "MrMedCashPage",
        path: "/userdata/mrmed-cash",
        componentPath: "pages/Dashboard/UserData/MrMedCash",
        name: "MrMedCashPage",
        auth: false,
        exact: true,
      },
      {
        component: "AddUserPage",
        path: "/userdata/add",
        componentPath: "pages/Dashboard/UserData/AddUser",
        name: "AddUserPage",
        auth: false,
        exact: true,
      },
      {
        component: "UserDetailPage",
        path: "/userdata/details",
        componentPath: "pages/Dashboard/UserData/UserDetails",
        name: "UserDetailPage",
        auth: false,
        exact: true,
      },
      {
        component: "EditUserPage",
        path: "/userdata/edit",
        componentPath: "pages/Dashboard/UserData/EditUser",
        name: "EditUserPage",
        auth: false,
        exact: true,
      },
      {
        component: "BulkUploadPage",
        path: "/userdata/bulk-upload",
        componentPath: "pages/Dashboard/UserData/bulkUpload",
        name: "BulkUploadPage",
        auth: false,
        exact: true,
      },
      // COUPON MANAGEMENT
      {
        component: "CouponDetails",
        path: "/couponmanagement/coupon",
        componentPath: "pages/Dashboard/CouponManagement/CouponDetails",
        name: "CouponDetails",
        auth: false,
        exact: true,
      },
      {
        component: "AddCoupon",
        path: "/couponmanagement/add",
        componentPath: "pages/Dashboard/CouponManagement/AddCoupon",
        name: "AddCoupon",
        auth: false,
        exact: true,
      },
      {
        component: "EditCoupon",
        path: "/couponmanagement/edit",
        componentPath: "pages/Dashboard/CouponManagement/EditCoupon",
        name: "EditCoupon",
        auth: false,
        exact: true,
      },

      {
        component: "ViewCouponLog",
        path: "/couponmanagement/log",
        componentPath: "pages/Dashboard/CouponManagement/ViewCouponLog",
        name: "ViewCouponLog",
        auth: false,
        exact: true,
      },

      // offer Management

      {
        component: "OfferManagement",
        path: "/offermanagement/offers",
        componentPath: "pages/Dashboard/OfferManagement/OfferDetails",
        name: "OfferDetails",
        auth: false,
        exact: true,
      },
      {
        component: "AddOffer",
        path: "/offermanagement/addoffer",
        componentPath: "pages/Dashboard/OfferManagement/AddOffer",
        name: "AddOffer",
        auth: false,
        exact: true,
      },
      {
        component: "EditOffer",
        path: "/offermanagement/editoffer",
        componentPath: "pages/Dashboard/OfferManagement/EditOffer",
        name: "EditOffer",
        auth: false,
        exact: true,
      },



      // ORDER MANAGEMENT
      {
        component: "OrderManagement",
        path: "/ordermanagement/list",
        componentPath: "pages/Dashboard/OrderManagement/OrderManagement",
        name: "OrderManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddMedicinePage",
        path: "/ordermanagement/add",
        componentPath: "pages/Dashboard/OrderManagement/AddMedicine",
        name: "AddMedicinePage",
        auth: false,
        exact: true,
      },
      {
        component: "UserDataPage",
        path: "/ordermanagement/user",
        componentPath: "pages/Dashboard/OrderManagement/UserData",
        name: "UserDataPage",
        auth: false,
        exact: true,
      },
      {
        component: "UploadPrescriptionPage",
        path: "/ordermanagement/create-order",
        componentPath: "pages/Dashboard/OrderManagement/CreateOrder",
        name: "UploadPrescriptionPage",
        auth: false,
        exact: true,
      },
      {
        component: "OrderDetailPage",
        path: "/ordermanagement/details",
        componentPath: "pages/Dashboard/OrderManagement/OrderDetail",
        name: "OrderDetailPage",
        auth: false,
        exact: true,
      },
      {
        component: "CustomMessagePage",
        path: "/ordermanagement/custom-message",
        componentPath: "pages/Dashboard/OrderManagement/CustomMessage",
        name: "CustomMessagePage",
        auth: false,
        exact: true,
      },
      //HUB
      {
        component: "HubList",
        path: "/hub",
        componentPath: "pages/Dashboard/Hub/Hub",
        name: "HubList",
        auth: false,
        exact: true,
      },
      {
        component: "AddHub",
        path: "/hub/add",
        componentPath: "pages/Dashboard/Hub/AddHub",
        name: "AddHub",
        auth: false,
        exact: true,
      },
      {
        component: "EditHub",
        path: "/hub/edit",
        componentPath: "pages/Dashboard/Hub/EditHub",
        name: "EditHub",
        auth: false,
        exact: true,
      },
      //PINCODE MANAGEMENT
      {
        component: "PincodeManagement",
        path: "/pincodemanagement/list",
        componentPath: "pages/Dashboard/PincodeManagement/PincodeManagement",
        name: "PincodeManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddPincode",
        path: "/pincodemanagement/add",
        componentPath: "pages/Dashboard/PincodeManagement/AddPincode",
        name: "AddPincode",
        auth: false,
        exact: true,
      },
      {
        component: "EditPincode",
        path: "/pincodemanagement/edit",
        componentPath: "pages/Dashboard/PincodeManagement/EditPincode",
        name: "EditPincode",
        auth: false,
        exact: true,
      },
      {
        component: "PincodeBulkUpload",
        path: "/pincodeManagement/bulkUpload",
        componentPath: "pages/Dashboard/PincodeManagement/BulkUpload.js",
        name: "PincodeBulkUpload",
        auth: false,
        exact: true,
      },
      // BANNER MANAGEMENT
      {
        component: "BannerDetails",
        path: "/bannermanagement/banner",
        componentPath: "pages/Dashboard/BannerManagement/BannerDetails",
        name: "BannerDetails",
        auth: false,
        exact: true,
      },
      {
        component: "AddBanner",
        path: "/bannermanagement/add",
        componentPath: "pages/Dashboard/BannerManagement/AddBanner",
        name: "AddBanner",
        auth: false,
        exact: true,
      },
      {
        component: "EditBanner",
        path: "/bannermanagement/edit",
        componentPath: "pages/Dashboard/BannerManagement/EditBanner",
        name: "EditBanner",
        auth: false,
        exact: true,
      },
      // PRODUCT MANAGEMENT
      {
        component: "ProductDetails",
        path: "/productmanagement/product",
        componentPath: "pages/Dashboard/ProductManagement/ProductDetails",
        name: "ProductDetails",
        auth: false,
        exact: true,
      },
      {
        component: "AddProduct",
        path: "/productmanagement/add",
        componentPath: "pages/Dashboard/ProductManagement/AddProduct",
        name: "AddProduct",
        auth: false,
        exact: true,
      },
      {
        component: "EditProduct",
        path: "/productmanagement/edit",
        componentPath: "pages/Dashboard/ProductManagement/EditProduct",
        name: "EditProduct",
        auth: false,
        exact: true,
      },
      {
        component: "ViewProduct",
        path: "/productmanagement/view",
        componentPath: "pages/Dashboard/ProductManagement/ViewProduct",
        name: "ViewProduct",
        auth: false,
        exact: true,
      },
      {
        component: "BulkUpload",
        path: "/productmanagement/bulkUpload",
        componentPath: "pages/Dashboard/ProductManagement/BulkUpload",
        name: "BulkUpload",
        auth: false,
        exact: true,
      },
      // CONTENT MANAGEMENT
      {
        component: "ContentDetails",
        path: "/contentmanagement/content",
        componentPath: "pages/Dashboard/ContentManagement/ContentDetails",
        name: "ContentDetails",
        auth: false,
        exact: true,
      },
      {
        component: "AddContent",
        path: "/contentmanagement/add",
        componentPath: "pages/Dashboard/ContentManagement/AddContent",
        name: "AddContent",
        auth: false,
        exact: true,
      },
      //MOLECULE MANAGEMENT
      {
        component: "MoleculeManagement",
        path: "/moleculeManagement/product",
        componentPath: "pages/Dashboard/MoleculeManagement/MoleculeManagement",
        name: "MoleculeManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddMolecule",
        path: "/moleculeManagement/add",
        componentPath: "pages/Dashboard/MoleculeManagement/AddMolecule",
        name: "AddMolecule",
        auth: false,
        exact: true,
      },
      {
        component: "EditMolecule",
        path: "/moleculeManagement/edit",
        componentPath: "pages/Dashboard/MoleculeManagement/EditMolecule",
        name: "EditMolecule",
        auth: false,
        exact: true,
      },
      {
        component: "ViewDetails",
        path: "/moleculeManagement/viewDetails",
        componentPath: "pages/Dashboard/MoleculeManagement/ViewDetails",
        name: "ViewDetails",
        auth: false,
        exact: true,
      },
      {
        component: "MoleculeBulkUpload",
        path: "/moleculeManagement/bulkUpload",
        componentPath: "pages/Dashboard/MoleculeManagement/BulkUpload.js",
        name: "MoleculeBulkUpload",
        auth: false,
        exact: true,
      },
      {
        component: "MoleculeContent",
        path: "/moleculeManagement/editContent",
        componentPath: "pages/Dashboard/MoleculeManagement/MoleculeContent",
        name: "MoleculeContent",
        auth: false,
        exact: true,
      },
      // PAP REQUEST
      {
        component: "PapRequest",
        path: "/paprequest/list",
        componentPath: "pages/Dashboard/PapRequest/PapRequest",
        name: "PapRequest",
        auth: false,
        exact: true,
      },
      {
        component: "ViewPap",
        path: "/paprequest/view",
        componentPath: "pages/Dashboard/PapRequest/ViewPap",
        name: "ViewPap",
        auth: false,
        exact: true,
      },
      // IMPORT MEDICINE
      {
        component: "ImportMedicineRequest",
        path: "/importmedicine/list",
        componentPath: "pages/Dashboard/ImportMedicine/ImportMedicineRequest",
        name: "ImportMedicineRequest",
        auth: false,
        exact: true,
      },
      {
        component: "ImportMedicineDetails",
        path: "/importmedicine/details",
        componentPath: "pages/Dashboard/ImportMedicine/ImportMedicineDetails",
        name: "ImportMedicineDetails",
        auth: false,
        exact: true,
      },
      {
        component: "ViewImport",
        path: "/importmedicine/view",
        componentPath: "pages/Dashboard/ImportMedicine/ViewImportMedicine",
        name: "ViewImport",
        auth: false,
        exact: true,
      },
      // CARER REQUEST
      {
        component: "CarerRequest",
        path: "/carerrequest/list",
        componentPath: "pages/Dashboard/CarerRequest/CarerRequest",
        name: "CarerRequest",
        auth: false,
        exact: true,
      },
      {
        component: "ViewCarer",
        path: "/carerrequest/view",
        componentPath: "pages/Dashboard/CarerRequest/ViewCarer",
        name: "ViewCarer",
        auth: false,
        exact: true,
      },
      // SPECIALITY MANAGEMENT
      {
        component: "SpecialityDetails",
        path: "/conditionmanagement",
        componentPath: "pages/Dashboard/SpecialityManagement/SpecialityDetails",
        name: "SpecialityDetails",
        auth: false,
        exact: true,
      },
      {
        component: "AddSpeciality",
        path: "/conditionmanagement/add",
        componentPath: "pages/Dashboard/SpecialityManagement/AddSpeciality",
        name: "AddSpeciality",
        auth: false,
        exact: true,
      },
      {
        component: "EditSpeciality",
        path: "/conditionmanagement/edit",
        componentPath: "pages/Dashboard/SpecialityManagement/EditSpeciality",
        name: "EditSpeciality",
        auth: false,
        exact: true,
      },
      // CONDITION MANAGEMENT
      {
        component: "ConditionDetails",
        path: "/superspecialitymanagement",
        componentPath: "pages/Dashboard/ConditionManagement/ConditionDetails",
        name: "ConditionDetails",
        auth: false,
        exact: true,
      },
      {
        component: "AddCondition",
        path: "/superspecialitymanagement/add",
        componentPath: "pages/Dashboard/ConditionManagement/AddCondition",
        name: "AddCondition",
        auth: false,
        exact: true,
      },
      {
        component: "EditCondition",
        path: "/superspecialitymanagement/edit",
        componentPath: "pages/Dashboard/ConditionManagement/EditCondition",
        name: "EditCondition",
        auth: false,
        exact: true,
      },
      // HOMEPAGE MANAGEMENT
      {
        component: "CategoryDetails",
        path: "/homepagemanagement",
        componentPath: "pages/Dashboard/HomePageManagement/CategoryDetails",
        name: "CategoryDetails",
        auth: false,
        exact: true,
      },
      {
        component: "AddCategory",
        path: "/homepagemanagement/add",
        componentPath: "pages/Dashboard/HomePageManagement/AddCategory",
        name: "AddCategory",
        auth: false,
        exact: true,
      },
      {
        component: "EditCategory",
        path: "/homepagemanagement/edit",
        componentPath: "pages/Dashboard/HomePageManagement/EditCategory",
        name: "EditCategory",
        auth: false,
        exact: true,
      },
      // Review Management
      {
        component: "ReviewManagement",
        path: "/reviewmanagement",
        componentPath: "pages/Dashboard/ReviewManagement",
        name: "ReviewManagement",
        auth: false,
        exact: true,
      },
      // SUPPLIER MANAGEMENT
      {
        component: "SupplierManagement",
        path: "/suppliermanagement/list",
        componentPath: "pages/Dashboard/SupplierManagement/SupplierManagement",
        name: "SupplierManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddSupplier",
        path: "/supplierManagement/addSupplier",
        componentPath: "pages/Dashboard/SupplierManagement/AddSupplier",
        name: "AddSupplier",
        auth: false,
        exact: true,
      },
      {
        component: "EditSupplier",
        path: "/supplierManagement/editSupplier",
        componentPath: "pages/Dashboard/SupplierManagement/EditSupplier",
        name: "EditSupplier",
        auth: false,
        exact: true,
      },
      {
        component: "ViewSupplier",
        path: "/supplierManagement/details",
        componentPath: "pages/Dashboard/SupplierManagement/ViewSupplier",
        name: "ViewSupplier",
        auth: false,
        exact: true,
      },
      // BLOG
      {
        component: "BlogDetails",
        path: "/blog",
        componentPath: "pages/Dashboard/Blog/BlogDetails",
        name: "BlogDetails",
        auth: false,
        exact: true,
      },
      {
        component: "EditBlog",
        path: "/blog/edit",
        componentPath: "pages/Dashboard/Blog/EditBlog",
        name: "EditBlog",
        auth: false,
        exact: true,
      },
      {
        component: "AddBlog",
        path: "/blog/add",
        componentPath: "pages/Dashboard/Blog/AddBlog",
        name: "AddBlog",
        auth: false,
        exact: true,
      },
      {
        component: "ViewBlog",
        path: "/blog/view",
        componentPath: "pages/Dashboard/Blog/ViewBlog",
        name: "ViewBlog",
        auth: false,
        exact: true,
      },
      {
        component: "BlogCategory",
        path: "/blog/category",
        componentPath: "pages/Dashboard/Blog/BlogCategory",
        name: "BlogCategory",
        auth: false,
        exact: true,
      },
      {
        component: "AddCategoryBlog",
        path: "/blog/addcategory",
        componentPath: "pages/Dashboard/Blog/AddCategory",
        name: "AddCategoryBlog",
        auth: false,
        exact: true,
      },
      {
        component: "EditCategoryBlog",
        path: "/blog/editcategory",
        componentPath: "pages/Dashboard/Blog/EditCategory",
        name: "EditCategoryBlog",
        auth: false,
        exact: true,
      },
      // NEWS
      {
        component: "NewsDetails",
        path: "/news",
        componentPath: "pages/Dashboard/News/NewsDetails",
        name: "NewsDetails",
        auth: false,
        exact: true,
      },
      {
        component: "EditNews",
        path: "/news/edit",
        componentPath: "pages/Dashboard/News/EditNews",
        name: "EditNews",
        auth: false,
        exact: true,
      },
      {
        component: "AddNews",
        path: "/news/add",
        componentPath: "pages/Dashboard/News/AddNews",
        name: "AddNews",
        auth: false,
        exact: true,
      },
      // FAQ MANAGEMENT
      {
        component: "FaqDetails",
        path: "/faqmanagement",
        componentPath: "pages/Dashboard/FaqManagement/FaqDetails",
        name: "FaqDetails",
        auth: false,
        exact: true,
      },
      {
        component: "AddFaq",
        path: "/faqmanagement/add",
        componentPath: "pages/Dashboard/FaqManagement/AddFaq",
        name: "AddFaq",
        auth: false,
        exact: true,
      },
      {
        component: "EditFaq",
        path: "/faqmanagement/edit",
        componentPath: "pages/Dashboard/FaqManagement/EditFaq",
        name: "EditFaq",
        auth: false,
        exact: true,
      },
      {
        component: "ViewFaq",
        path: "/faqmanagement/view",
        componentPath: "pages/Dashboard/FaqManagement/ViewFaq",
        name: "ViewFaq",
        auth: false,
        exact: true,
      },
      // ADMIN SETTINGS
      {
        component: "Profile",
        path: "/account/view",
        componentPath: "pages/Dashboard/AdminSettings/Profile",
        name: "Profile",
        auth: false,
        exact: true,
      },
      {
        component: "EditProfile",
        path: "/account/edit",
        componentPath: "pages/Dashboard/AdminSettings/EditProfile",
        name: "EditProfile",
        auth: false,
        exact: true,
      },
      {
        component: "ChangePassword",
        path: "/account/changepassword",
        componentPath: "pages/Dashboard/AdminSettings/ChangePassword",
        name: "ChangePassword",
        auth: false,
        exact: true,
      },
      // NOTIFICATION
      {
        component: "Notification",
        path: "/notification",
        componentPath: "pages/Dashboard/Notification/Notification",
        name: "Notification",
        auth: false,
        exact: true,
      },
      // GLOBAL VARIANTS
      {
        component: "GlobalVariable",
        path: "/globalVariables",
        componentPath: "pages/Dashboard/GlobalVariable/GlobalVariable",
        name: "GlobalVariable",
        auth: false,
        exact: true,
      },
      // Subscribers
      {
        component: "Subscribers",
        path: "/subscribers/list",
        componentPath: "pages/Dashboard/Subscriber/Subscriber",
        name: "Subscribers",
        auth: false,
        exact: true,
      },
      // TERMS AND CONDITIONS
      {
        component: "TermsAndCondition",
        path: "/terms&condition",
        componentPath: "pages/Dashboard/TermsAndCondition/TermsAndCondition",
        name: "TermsAndConditon",
        auth: false,
        exact: true,
      },
      {
        component: "EditTermsAndCondition",
        path: "/terms&condition/edit",
        componentPath:
          "pages/Dashboard/TermsAndCondition/EditTermsAndCondition",
        name: "EditTermsAndCondition",
        auth: false,
        exact: true,
      },
      // STOCK NOTIFICATION
      {
        component: "StockPage",
        path: "/stockNotification/list",
        componentPath: "pages/Dashboard/StockNotification/stock",
        name: "StockPage",
        auth: false,
        exact: true,
      },
      // FEEDBACK MANAGEMENT
      {
        component: "FeedBackPage",
        path: "/feedback/list",
        componentPath: "pages/Dashboard/FeedBack/FeedBack",
        name: "FeedBackPage",
        auth: false,
        exact: true,
      },
      {
        component: "FeedBackDetailPage",
        path: "/feedback/detail",
        componentPath: "pages/Dashboard/FeedBack/FeedBackDetail",
        name: "FeedBackDetailPage",
        auth: false,
        exact: true,
      },
       //LEAD MANAGEMENT ADMIN
       {
        component: "LeadManagement",
        path: "/leadmanagement/list",
        componentPath: "pages/Dashboard/LeadManagement/LeadAdmins",
        name: "LeadAdmins",
        auth: false,
        exact: true,
      },
      {
        component: "LeadManagementLog",
        path: "/leadmanagement/logs",
        componentPath: "pages/Dashboard/LeadManagement/LeadLog",
        name: "LeadLogs",
        auth: false,
        exact: true,
      },
    ],
  },
];
export default routers;
