import React from "react";

export const AuthLayout = ({ children }) => {
  return (
    <div className="auth-layout">
      <div className="left-pills">
        <img src={require("../assets/images/big-left.png")} />
      </div>
      <div className="left-small-pills">
        <img src={require("../assets/images/small-left.png")} />
      </div>
      <div className="container">
        <img
          id="logo"
          draggable={false}
          src={require("../assets/images/login-logo.png")}
        />
        {children}
      </div>
      <div className="right-pills">
        <img src={require("../assets/images/big-right.png")} />
      </div>
      <div className="right-small-pills">
        <img src={require("../assets/images/small-right.png")} />
      </div>
    </div>
  );
};
