import axios from "axios";
import {getToken} from "../helpers"
import {endpoints, notificationURL} from "config/api";

export const singleImageUpload = async (data) => {
  let request = await axios({
    method: "post",
    url: endpoints.common.SINGLE_IMAGE_UPLOAD,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
};

export const multipleImageUpload = async (data) => {
  let request = await axios({
    method: "post",
    url: endpoints.common.MULTIPLE_IMAGE_UPLOAD,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
};

export const createPrescription = async (data) => {
  let request = await axios({
    method: "post",
    url: endpoints.common.CREATE_PRESCRIPTION,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
};

export const getStockNotification = async (params) => {
  let request = await axios({
    method: "get",
    url: endpoints.common.GET_STOCK_NOTIFICATION,
    headers: {
      Authorization: getToken(),
    },
    params: params,
  });
  return request;
};

export const getAllFeedBack = async (params) => {
  let request = await axios({
    method: "get",
    url: endpoints.common.GET_ALL_FEEDBACK,
    headers: {
      Authorization: getToken(),
    },
    params: params,
  });
  return request;
};

export const getSubscriber = async (params) => {
  let request = await axios({
    method: "get",
    url: endpoints.common.GET_SUBSCRIBER,
    headers: {
      Authorization: getToken(),
    },
    params: params,
  });
  return request;
};

export const getGlobalVariables = async () => {
  let request = await axios({
    method: "get",
    url: endpoints.common.GLOBAL_VARIABLES,
    headers: {
      Authorization: getToken(),
    },
  });
  return request;
};

export const updateGlobalVariables = async (data) => {
  let request = await axios({
    method: "put",
    url: endpoints.common.UPDATE_GLOBAL_VARIABLES,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
};

export const deliveryCharge = async () => {
  let request = await axios({
    method: "get",
    url: endpoints.common.DELIVERY_CHARGE,
    headers: {
      Authorization: getToken(),
    },
  });
  return request;
};

export const updateDeliveryCharge = async (data) => {
  let request = await axios({
    method: "put",
    url: endpoints.common.DELIVERY_CHARGE,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
};

export const addDeliveryCharge = async (data) => {
  let request = await axios({
    method: "post",
    url: endpoints.common.ADD_DELIVERY_CHARGE,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
};

export const deleteDeliveryCharge = async (id) => {
  let request = await axios({
    method: "delete",
    url: `${endpoints.common.DELETE_DELIVERY_CHARGE}/${id}`,
    headers: {
      Authorization: getToken(),
    },
  });
  return request;
};

export const getTerms = async () => {
  let request = await axios({
    method: "get",
    url: endpoints.common.GET_TERMS,
    headers: {
      Authorization: getToken(),
    },
  });
  return request;
};

export const getAllStates = async () => {
  let request = await axios({
    method: "get",
    url: endpoints.common.GET_ALL_STATES,
    headers: {
      Authorization: getToken(),
    },
  });
  return request;
};

export const validateToken = async () => {
  let request = await axios({
    method: "get",
    url: endpoints.common.VALIDATE_TOKEN,
    headers: {
      Authorization: getToken(),
    },
  });
  return request;
};

export const getTermsById = async (id) => {
  let request = await axios({
    method: "get",
    url: `${endpoints.common.GET_TERMS_BY_ID}/${id}`,
    headers: {
      Authorization: getToken(),
    },
  });
  return request;
};

export const updateTerms = async (data) => {
  let request = await axios({
    method: "put",
    url: endpoints.common.UPDATE_TERMS,
    headers: {
      Authorization: getToken(),
    },
    data: data,
  });
  return request;
};

export const excelDownload = async (url, params = {}, type) => {
  return await axios({
    method: url == `${notificationURL}/order/generateOrderSheet` ? "post" : "get",
    url: url,
    responseType: type ? "json" : "blob",
    params: params,
    headers: {
      Authorization: getToken(),
    },
  });
};

export const moleculeExcelDownload = async (url, params = {}, type) => {
  return await axios({
    method: "post",
    url: url,
    responseType: type ? "json" : "blob",
    params: params,
    headers: {
      Authorization: getToken(),
    },
  });
};

